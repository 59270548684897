<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'About',

    metaInfo: { title: 'About Us' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-team',
        'we-are-aptamimetics',
        'video-interview',
        //  'supporters',
        'we-help-your-success',
        'partners',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'about-us',
      },
    },
  }
</script>
